<template>
  <div class="send-instrument">
    <h2>科研仪器列表</h2>
    <div class="header-btn">
      <el-button type="primary" @click="()=>{$router.push('/addInstrument')}">
        <i class="el-icon-plus"></i> 添加科研仪器
      </el-button>
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" prop="image" label="仪器图片">
          <template slot-scope="scope">
            <img v-if="scope.row.image" :src="scope.row.image" alt width="90px" height="70px" />
            <img v-else src="@/assets/images/暂无图片.png" alt width="90px" height="70px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="仪器名称"></el-table-column>
        <el-table-column align="center" prop="big_category.name" label="所属分类"></el-table-column>
        <el-table-column align="center" prop="get_date" label="购置日期"></el-table-column>
        <el-table-column align="center" prop="company" label="所属单位"></el-table-column>
        <el-table-column align="center" prop="work_status" label="仪器工作状态"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="editClick(scope.row)" type="primary" size="mini">编辑</el-button>
            <el-button @click="del(scope.row)" type="danger" size="mini">删除</el-button>

            <el-button @click="orderInfo(scope.row)" class="order" type="primary" size="mini">
              预约信息
              <!-- <span
                  class="order-tip"
                  v-if="scope.row.noread_order!=0"
              >{{scope.row.noread_order}}</span>-->
              <span class="order-tip" v-if="scope.row.noread_order!=0">{{scope.row.all_order}}</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <page class="page" :total="page.total" @currentPageChange="currentPageChange" />
    </div>
  </div>
</template>
<script>
import page from "@/components/PageComponents";
import { success, warning, error } from "@/utils/notification";
export default {
  name: "sendInstrument",
  components: { page },
  data() {
    return {
      tableData: [], //表格数据
      organ_id: 0, //机构id
      page: { current_page: 0, per_page: 0, total: 0, last_page: 0 },
      nowPage: 1 //当前page
    };
  },
  created() {
    this.organ_id = sessionStorage.organizationId;
    this.instrumentsList(this.organ_id, 1);
  },
  methods: {
    //科研仪器列表
    async instrumentsList(organ_id, page) {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.instruments,
        {
          params: { organ_id, page }
        }
      );
      this.tableData = res.data.data;
      this.tableData.forEach(item => {
        item.work_status == 0 && (item.work_status = "不可用");
        item.work_status == 1 && (item.work_status = "正常");
        item.work_status == 2 && (item.work_status = "维修");
      });
      this.page = res.data.meta;
      console.log(res, "科研仪器列表");
    },
    //编辑单条科技资源
    editClick(data) {
      this.$router.push({
        path: "/addInstrument",
        query: { id: data.id }
      });
    },
    //分页
    currentPageChange(e) {
      this.nowPage = e;
      this.instrumentsList(this.organ_id, e);
    },
    //删除
    del(data) {
      this.$confirm("此操作将永久删除该仪器信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http
          .delete(this.URL.adminUrl.instruments + data.id, {
            params: { organ_id: this.organ_id }
          })
          .then(res => {
            success("删除成功");
            this.instrumentsList(this.organ_id, this.nowPage);
            console.log(res, "删除删除删除");
          });
      });
    },
    //跳转到预约信息列表
    orderInfo(data) {
      this.ordersPut(data.id);
      this.$router.push({
        path: "/orderInfo",
        query: { id: data.id }
      });
    },
    //预约消息设置已读
    async ordersPut(id) {
      const { data: res } = await this.$http.put(
        this.URL.adminUrl.instruments + id + "/orders/readAll",
        { organ_id: this.organ_id }
      );
      console.log(res, "预约消息设置已读");
    }
  }
};
</script>
<style lang="less" scoped>
.send-instrument {
  .header-btn {
    text-align: right;
    margin: 20px 0;
  }
  .page {
    text-align: center;
    margin-top: 10px;
  }
  .order {
    position: relative;
    margin-top: 3px;
    .order-tip {
      //预约tip
      position: absolute;
      top: 2px;
      right: 4px;
      color: red;
      font-size: 14px;
    }
  }
}
</style>